// .stickyHeader {
//   position: sticky;
//   top: 0px
// }

.subregionCell {
  padding-left: 1em !important;
}

.territoryCell {
  padding-left: 2em !important;
}