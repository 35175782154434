.colCanonical,
.colEntity,
.colValue,
.colDataset,
.colEntity {
    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.colCanonical,
.colEntity {
    max-width: 8em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 1%;
    // font-family: monospace;
}

.colCanonical,
.colProp,
.colDataset {
    border-right: 0 !important;
    padding-right: 1px !important;
}

.colValue {
    max-width: 25em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.colDate,
.colLang {
    width: 1%;
    white-space: nowrap;
}

.colExpand {
    max-width: 16px;
    border-left: 0 !important;
    padding-left: 0 !important;
}