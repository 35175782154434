@import './variables.scss';
@import "~bootstrap/scss/badge";


.search {
    input[type=search] {
        border: 0;
    }
}

.stats {
    margin-top: 1em;

    a {
        color: white;
        text-decoration: underline;
    }

    :global(.badge) {
        color: white;
        background-color: shift-color($primary, $link-shade-percentage) !important;
    }
}

.commercialBanner {
    background-color: $blue-200;
    margin-top: 3em;
    padding-top: 2em;
    padding-bottom: 3em;

    :global(h2) {
        color: $blue-fg;
        margin-bottom: 1em;
    }

    :global(p) {
        color: $blue-fg;
        margin-bottom: 1em;
    }
}
